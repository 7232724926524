* {
  box-sizing: border-box;
}

/* 
	Active class to handle active sidebar item
*/
.active {
  background: #e3e9ef;
  margin-left: 0;
  border-radius: 5px;
  padding-left: 0 !important;
  padding-right: 10px;
  color: #001d69 !important;
}
.active::before {
  content: "";
  padding: 0;
  width: 8px;
  height: 100%;
  background: #001d69;
  border-radius: 5px;
}

.activeTab {
  font-size: 14px;
  font-weight: 400 !important;
  background-color: #001d69 !important;
  color: #fff !important;
  padding: 20px;
  border-radius: 20px;
}

.activeSide {
  background-color: #fff !important;
  color: #812990 !important;
}

.ps-sidebar-container,
.ps-submenu-content {
  background: none !important;
}

.ps-menu-button:hover {
  background: none !important;
}

.ps-menu-icon svg {
  font-size: 1rem !important;
}

.ps-menu-label {
  font-size: 12px !important;
}
