* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.loader {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.loader span {
  margin-top: 15px;
  font-size: 14px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid transparent; /* Light grey */
  border-top: 5px solid #005824; /* Black */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

/* React Quilll Editor */

.ql-toolbar {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1;
  border-bottom: 1px solid #ccc;
}

/* Adjust the editor container to accommodate the sticky toolbar */
.ql-container.ql-snow {
  height: 350px;
  overflow-y: auto;
  box-sizing: border-box;
}

.react-tags-input .react-tagsinput {
  margin-top: 5px;
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
}
